import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useStore } from '../../zustand/stores';
const Loader = dynamic(() => import('../Loader'), { ssr: false });

export const GlobalLoader: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setSearchSessionId } = useStore((state) => state.biSlice);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
      if (!url.includes('/search') && router.asPath.includes('/search')) {
        setSearchSessionId('');
      }
      if (!shallow) {
        setIsLoading(true);
      }
    };

    const handleRouteChangeComplete = (url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        setIsLoading(false);
      }
    };


    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);
  return isLoading ? <Loader /> : null;
};
