import { useRouter } from 'next/router';

import { KA_SEARCH_EXPANDED_LOCALES, LOCALES } from '../constants';

export const useIsKnowledgeAgentSearchEnabled = () => {
  const { locale } = useRouter();

  if (LOCALES.EN === locale || KA_SEARCH_EXPANDED_LOCALES.includes(locale as string)) {
    return true;
  }

  return false;
};
