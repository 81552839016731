import { Box, PopoverMenu, Text } from '@wix/design-system';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useContext } from 'react';
import { ChevronLeft, ChevronRight, Languages } from 'wix-ui-icons-common';

import { LOCALES, PRODUCTION_DOMAIN } from '../../../../constants';
import { Context } from '../../../../context';
import { DATA_HOOKS } from '../../../../dataHooks';
import { isRTL } from '../../../../utils/internationalization';
import { useHeaderBI } from '../../bi';

import css from './index.module.scss';

export const LANGUAGE_NAMES: Record<string, string> = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  he: 'עברית',
  it: 'Italiano',
  ja: '日本語',
  ko: '한국어',
  nl: 'Nederlands',
  pt: 'Português',
  ru: 'Русский',
  tr: 'Türkçe',
  uk: 'Українська',
};

export const LanguageMenu: FunctionComponent = () => {
  const { asPath, locale } = useRouter();
  const { isLoggedInUser } = useContext(Context);
  const isRTLLanguage = isRTL(locale);
  const { onLinkClick } = useHeaderBI();

  const onLanguageChange = async (language: string) => {
    const url = `/${language}${asPath === '/' ? '' : asPath}`;
    await onLinkClick(LANGUAGE_NAMES[language], `https://${PRODUCTION_DOMAIN}${url}`);
    location.href = url;
  };

  return (
    <PopoverMenu
      className={css.languageMenu}
      dataHook={DATA_HOOKS.HEADER_LANGUAGE_MENU}
      placement={isLoggedInUser ? 'left' : 'bottom'}
      moveBy={isLoggedInUser ? { x: 0, y: 20 } : { x: 0, y: 0 }}
      showArrow={!isLoggedInUser}
      triggerElement={({ open, close, toggle }) =>
        isLoggedInUser ? (
          <div
            onMouseEnter={open}
            onMouseLeave={close}
            className={css.loggedInButton}
          >
            <Box gap={1} position='relative'>
              <Languages color='#000624' className={css.langaugesMenuIconLoggedIn} />
              <Text className={css.languageMenuText}>{LANGUAGE_NAMES[locale as string]}</Text>
            </Box>
            {isRTLLanguage ? (
              <ChevronLeft color='#000624' />
            ) : (
              <ChevronRight color='#000624' />
            )}
          </div>
        ) : (
          <Languages
            onClick={toggle}
            data-hook={DATA_HOOKS.HEADER_LANGUAGE_MENU_BUTTON}
            className={css.languageMenuIcon}
          />
        )
      }
    >
      {Object.values(LOCALES).map((lang) => (
        <PopoverMenu.MenuItem
          key={lang}
          onClick={() => onLanguageChange(lang)}
          text={
            <div
              className={classNames(css.language, {
                [css.selectedLanguage]: locale === lang,
              })}
            >
              {LANGUAGE_NAMES[lang]}
            </div>
          }
        />
      ))}
    </PopoverMenu>
  );
};
