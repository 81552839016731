import { Accordion, Box, Text } from '@wix/design-system';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import { ChevronDown, ChevronUp, Languages } from 'wix-ui-icons-common';

import { PRODUCTION_DOMAIN } from '../../../../../constants';
import { DATA_HOOKS } from '../../../../../dataHooks';
import { useHeaderBI } from '../../../bi';
import { LANGUAGE_NAMES } from '../../../HeaderRight/LanguageMenu';

import css from './index.module.scss';

const LanguagesAccordion: React.FC = () => {
  const { locale, asPath } = useRouter();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { onLinkClick } = useHeaderBI();

  return (
    <Box className={css.languagesAccordion}>
      <Accordion
        size='medium'
        hideShadow
        dataHook={DATA_HOOKS.HEADER_SMALL_SCREEN_MENU_LANGUAGES_ACCORDION}
        items={[
          {
            onToggle: () => {
              setIsExpanded(!isExpanded);
            },
            open: isExpanded,
            title: (
              <Box gap={2}>
                <Languages />
                <Text className={classNames({ [css.expanded]: isExpanded })}>
                  {LANGUAGE_NAMES[locale as string]}
                </Text>
              </Box>
            ),
            children: (
              <Box className={css.languages}>
                {Object.keys(LANGUAGE_NAMES).map((language) => (
                  <a
                    href={`/${language}${asPath === '/' ? '' : asPath}`}
                    key={language}
                    data-hook={DATA_HOOKS.HEADER_SMALL_SCREEN_MENU_LANGUAGES_ACCORDION_LINK}
                    onClick={async () => {
                      await onLinkClick(LANGUAGE_NAMES[language], `https://${PRODUCTION_DOMAIN}/${language}${asPath === '/' ? '' : asPath}`);
                    }}
                  >
                    <Text
                      size='small'
                      key={language}
                      className={classNames({
                        [css.selected]: language === locale,
                      })}
                    >
                      {LANGUAGE_NAMES[language]}
                    </Text>
                  </a>
                ))}
              </Box>
            ),
            buttonType: 'node',
            showLabel: 'always',
            expandLabel: <ChevronDown />,
            collapseLabel: <ChevronUp />,
          },
        ]}
      />
    </Box>
  );
};
export default LanguagesAccordion;
