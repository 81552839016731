import { useRouter } from 'next/router';
import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { pushUrl } from '../components/InnerLink';
import { Context } from '../context';
import { PageType } from '../types';
import { useIsSitePickerEnabled } from './useIsSitePickerEnabled';
import { useStore } from '../zustand/stores';

export type GoToSearchResultsPageParams = {
  term: string;
  previousPageType: PageType;
  overrideTerm?: string;
};

type QueryParams = {
  term: string;
  previousPageType: PageType;
  overrideTerm?: string;
};

export const useGoToSearchResultsPage = () => {
  const { locale } = useRouter();
  const { siteSelectorData } = useContext(Context);
  const setSearchSessionId = useStore((state) => state.biSlice.setSearchSessionId);
  const isSitePickerEnabled = useIsSitePickerEnabled();
  const editorType = isSitePickerEnabled ? siteSelectorData?.selectedSite?.editor?.editorType : '';

  const goToSearchResultsPage = async ({ term, previousPageType, overrideTerm = '' }: GoToSearchResultsPageParams) => {
    setSearchSessionId(uuidv4());

    const queryParams: QueryParams = { term: encodeURIComponent(term.trim()), previousPageType };

    if (overrideTerm) {
      Object.assign(queryParams, { inputType: 'suggested question', term: overrideTerm.trim() });
    }

    if (editorType) {
      Object.assign(queryParams, { editorType });
    }

    await pushUrl({
      pathname: `/${locale}/search`,
      query: queryParams,
    });
  };
  return { goToSearchResultsPage };
};
