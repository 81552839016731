import { useEffect } from 'react';

const useDisableScroll = (shouldDisable: boolean) => {
  useEffect(() => {
    document.body.style.overflow = shouldDisable ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [shouldDisable]);

  return null;
};

export default useDisableScroll;
