import { helpCenterModals } from '@wix/bi-logger-customer-care-data/v2';
import { helpCenterModalsParams } from '@wix/bi-logger-customer-care-data/v2/types';
import { useRouter } from 'next/router';
import { useCallback, useContext, useMemo } from 'react';

import { BI } from '../../../constants';
import { Context } from '../../../context';
import { useBI } from '../../../hooks/useBI';
import { mapPageTypeToSourceName } from '../../../utils/bi';

export const useSiteSelectorBI = (sessionId: string, itemId?: string) => {
    const { sessionBI, pageType } = useContext(Context);
    const { locale } = useRouter();
    const { sendBIEvent } = useBI();

    const sharedProps: Partial<helpCenterModalsParams> = useMemo(() => ({
        modal_name: BI.MODAL_NAME.SITE_PICKER,
        modal_session_id: sessionId,
        source_name: mapPageTypeToSourceName(pageType),
        self_service_session_id: sessionBI.selfServiceSessionId || undefined,
        kb_lang: locale,
        screen_name: BI.SCREEN_NAME.MAIN,
    }), [sessionId, pageType, locale, sessionBI.selfServiceSessionId]);

    const getClickedButtonName = (clickedItemId?: string) => {
        if (clickedItemId) {
            return BI.CLICKED_ITEM_TYPES.SPECIFIC_SITE;
        } else {
            return BI.CLICKED_ITEM_TYPES.ALL_SITES;
        }
    };

    return {
        onClickSiteOrAllSites: useCallback((previousSelectedSiteId?: string, clickedItemId?: string) => {
            void sendBIEvent(
                helpCenterModals({
                    ...sharedProps,
                    action_type: BI.ACTION_TYPE.MODAL_CLICK,
                    clicked_button_name: getClickedButtonName(clickedItemId),
                    clicked_item_id: clickedItemId,
                    params: previousSelectedSiteId,
                    item_id: itemId,
                })
            );
        }, [sendBIEvent, sharedProps, itemId]),
    };
};
