import { Avatar, Box, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import { PRODUCTION_DOMAIN } from '../../../../../constants';
import { Context } from '../../../../../context';
import { DATA_HOOKS } from '../../../../../dataHooks';
import { useHeaderBI } from '../../../bi';

import css from './index.module.scss';

const Profile: React.FC = () => {
  const { user } = useContext(Context);
  const { asPath, locale } = useRouter();
  const { t } = useTranslation();
  const { onLinkClick } = useHeaderBI();
  const returnUrl = `https://${PRODUCTION_DOMAIN}/${locale}${asPath}`;
  const signOutUrl = `https://www.wix.com/signout?redirectTo=https%3A%2F%2Fsupport.wix.com%2Faccount%2Flogout%3FredirectUrl%3D${returnUrl}`;
  return (
    <Box className={css.wrapper} verticalAlign='middle'>
      <Avatar
        name={user?.firstName}
        imgProps={{
          src: user?.img,
        }}
        color='A1'
        size='size60'
      />
      <Box direction='vertical' gap={1}>
        <Text
          className={css.profileName}
          weight='bold'
          ellipsis
          dataHook={DATA_HOOKS.HEADER_SMALL_SCREEN_MENU_PROFILE_NAME}
        >
          {user?.firstName} {user?.lastName}
        </Text>
        <a
          href={signOutUrl}
          data-hook={DATA_HOOKS.HEADER_SMALL_SCREEN_MENU_PROFILE_SIGN_OUT}
          onClick={async () => {
            await onLinkClick(t('header.sign-out'), signOutUrl);
          }}
        >
          {t('header.sign-out')}
        </a>
      </Box>
    </Box>
  );
};
export default Profile;
