import { Article } from '@wix/ambassador-answers-search-proxy/types';
import Head from 'next/head';
import React, { FunctionComponent } from 'react';

import { LOCALES, SEO } from '../../../constants';
import { PageSeo } from '../../../types';
import { isMonthOldLaunchedFr } from '../../../utils/isMonthOldLaunchedFr';
import { stripHTML } from '../../../utils/text';
import { BasicSeoElements } from '../BasicSEO';

type ArticlePageProps = {
  article?: Article;
  pageSeo: PageSeo;
};

export const ArticlePageSEO: FunctionComponent<ArticlePageProps> = ({
  article,
  pageSeo,
}) => {

  const parseArticleDescription = (content?: string) => {
    if (!content) {
      return '';
    } else {
      const MAX_DESCRIPTION_LENGTH = 160;
      return stripHTML(content).trim().slice(0, MAX_DESCRIPTION_LENGTH);
    }
  };

  const description =
    article &&
    pageSeo?.description
      .replace(
        SEO.PLACEHOLDERS.ARTICLE_PREVIEW,
        parseArticleDescription(article.content)
      )
      .replace(SEO.PLACEHOLDERS.ARTICLE_TITLE, article?.title || '')
      .replace(SEO.PLACEHOLDERS.COMPANY_NAME, SEO.COMPANY_NAME);

  const title = pageSeo?.title
    .replace(SEO.PLACEHOLDERS.ARTICLE_TITLE, article?.title || '')
    .replace(SEO.PLACEHOLDERS.COMPANY_NAME, SEO.COMPANY_NAME);

  const alternateDefaultUrl = article?.translations?.find(
    (e) => e.locale === LOCALES.EN
  )?.url;

  const noIndexDueToOldFR = article && isMonthOldLaunchedFr(article as any);
  const robots = (noIndexDueToOldFR || article?.excludedFromHelpCenterContentScope) ?
    { ...pageSeo?.robots, noIndex: true }
    : pageSeo?.robots;

  return (
    <>
      <BasicSeoElements
        title={title}
        description={description}
        imageUrl={pageSeo?.imageUrl}
        robots={robots}
      />
      <Head>
        {article?.translations?.map(({ url, locale: localeTr }) => (
          <link rel="alternate" hrefLang={localeTr} href={url} key={url} />
        ))}
        {alternateDefaultUrl && (
          <link
            rel="alternate"
            hrefLang="x-default"
            href={alternateDefaultUrl}
          />
        )}
      </Head>
    </>
  );
};
