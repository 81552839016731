import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

export const useIsIntersecting = (
  ref: React.MutableRefObject<any> | string,
  marginTop = '0px'
) => {
  const { asPath } = useRouter();
  const [scrollState, setScrollState] = useState(false);

  useEffect(() => {
    let element: Element | null = null;
    let observer: IntersectionObserver | null = null;

    setScrollState(false);

    if (typeof ref === 'string') {
      element = document.querySelector(`[data-hook="${ref}"]`);
    } else if (ref && ref.current) {
      element = ref.current;
    }

    if (element) {
      observer = new IntersectionObserver(
        (entries) => {
          setScrollState(!entries[0].isIntersecting);
        },
        { rootMargin: `${marginTop} 0px 0px 0px` }
      );

      observer.observe(element);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [ref, marginTop, asPath]);

  return scrollState;
};
