import { Box } from '@wix/design-system';
import classNames from 'classnames';
import Image from 'next/image';
import React, { useContext, useEffect, useState } from 'react';

import ExpandableMenuItem from './ExpandableMenuItem';
import { BI } from '../../../constants';
import { Context } from '../../../context';
import { DATA_HOOKS } from '../../../dataHooks';
import useDisableScroll from '../../../hooks/useDisableScroll';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { useHeaderBI } from '../bi';

import css from './index.module.scss';

export type MenuItem = {
  name: string;
  description: string;
  uri: string;
  id: string;
  imageSrc?: string;
};

export enum ExpandableMenuType {
  TOPICS = 'topics',
  RESOURCES = 'resources',
}

export type ExpandableMenuProps = {
  showMenu: boolean;
  hideMenu: () => void;
  items: MenuItem[];
  type: ExpandableMenuType;
};

const ExpandableMenu: React.FC<ExpandableMenuProps> = ({
  showMenu,
  hideMenu,
  items,
  type,
}: ExpandableMenuProps) => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [hoveredItemImage, setHoveredItemImage] = useState<string | null>(null);
  const { isWixStudioUser } = useContext(Context);
  const { onItemView } = useHeaderBI();
  useDisableScroll(showMenu);

  const ref = useOutsideClick((e: MouseEvent) => {
    const dataHook =
      e.target && (e.target as HTMLElement).parentElement
        ? (e.target as HTMLElement).parentElement?.dataset.hook
        : null;
    if (
      [DATA_HOOKS.HEADER_TOPICS, DATA_HOOKS.HEADER_RESOURCES].includes(
        dataHook as string
      )
    ) {
      return;
    }
    hideMenu();
  });

  useEffect(() => {
    if (hoveredItem) {
      setHoveredItemImage(
        items.find((item) => item.id === hoveredItem)?.imageSrc || null
      );
    }
  }, [hoveredItem, items]);

  useEffect(() => {
    if (showMenu && type === ExpandableMenuType.RESOURCES) {
      void onItemView(isWixStudioUser ? BI.ITEM_NAMES.HEADER_RESOURCES_STUDIO : BI.ITEM_NAMES.HEADER_RESOURCES_DIY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu, isWixStudioUser]);

  const placeholderImage =
    type === ExpandableMenuType.TOPICS
      ? 'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/trending-articles/trending-articles-new.jpg'
      : 'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/resources/placeholder.png';

  return (
    <Box
      className={classNames(css.expandableMenu, {
        [css.showMenu]: showMenu,
      })}
      dataHook={DATA_HOOKS.HEADER_EXPANDABLE_MENU}
      direction='vertical'
    >
      <Box className={css.itemsAndPreview} ref={ref}>
        <Box className={css.items} direction={type === ExpandableMenuType.RESOURCES ? 'vertical' : 'horizontal'}>
          {items.map((item) => (
            <div className={css.item} key={item.id}>
              <ExpandableMenuItem
                item={item}
                innerLinks={type === ExpandableMenuType.TOPICS}
                hideMenu={hideMenu}
                hoveredItem={hoveredItem}
                setHoveredItem={setHoveredItem}
              />
            </div>
          ))}
        </Box>
        <Box className={css.preview} align='center'>
          {hoveredItemImage && (
            <Image
              src={hoveredItemImage}
              alt='preview-image'
              height={330}
              width={330}
              className={classNames(css.previewImage, {
                [css.show]: hoveredItem,
                [css.resources]: type === ExpandableMenuType.RESOURCES,
                [css.topics]: type === ExpandableMenuType.TOPICS,
              })}
              loading='lazy'
            />
          )}
          <Image
            src={placeholderImage}
            alt='placeholder-preview-image'
            height={330}
            width={330}
            className={classNames(css.previewImage, {
              [css.show]: !hoveredItem,
              [css.resources]: type === ExpandableMenuType.RESOURCES,
              [css.topics]: type === ExpandableMenuType.TOPICS,
            })}
            loading='lazy'
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ExpandableMenu;
