import { Box, Text } from '@wix/design-system';
import { useRouter } from 'next/router';
import React from 'react';

import { Resource, Topic } from '../../..';
import { PRODUCTION_DOMAIN } from '../../../../../constants';
import { DATA_HOOKS } from '../../../../../dataHooks';
import { InnerLink } from '../../../../InnerLink';
import { useHeaderBI } from '../../../bi';

import css from './index.module.scss';

export enum AccordionMenuItemType {
  TOPICS = 'topics',
  RESOURCES = 'resources',
}

export type AccordionMenuItemProps = {
  items: (Resource | Topic)[];
  type: AccordionMenuItemType;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
};

const AccordionMenuItem: React.FC<AccordionMenuItemProps> = ({
  items,
  type,
  setIsMenuOpen,
}: AccordionMenuItemProps) => {
  const { onLinkClick } = useHeaderBI();
  const { locale } = useRouter();
  const onClick = async (item: Resource | Topic) => {
    await onLinkClick(
      item.name,
      type === AccordionMenuItemType.RESOURCES
        ? item.uri
        : `https://${PRODUCTION_DOMAIN}/${locale}${item.uri}`,
      type === AccordionMenuItemType.TOPICS ? item.id : undefined
    );
    if (type === AccordionMenuItemType.TOPICS) {
      setIsMenuOpen(false);
    }
  };
  return (
    <Box className={css.items}>
      {items.map((item) => {
        const Link = type === AccordionMenuItemType.TOPICS ? InnerLink : 'a';

        return (
          <Link
            href={item.uri}
            key={item.id}
            target={
              type === AccordionMenuItemType.RESOURCES ? '_blank' : undefined
            }
            onClick={() => onClick(item)}
            {...(type === AccordionMenuItemType.TOPICS && {
              dataHook:
                DATA_HOOKS.HEADER_SMALL_SCREEN_MENU_ACCORDION_MENU_ITEM_LINK,
            })}
            {...(type === AccordionMenuItemType.RESOURCES && {
              'data-hook':
                DATA_HOOKS.HEADER_SMALL_SCREEN_MENU_ACCORDION_MENU_ITEM_LINK,
            })}
          >
            <Box direction='vertical' gap={1} className={css.item}>
              <Text weight='bold' size='tiny'>
                {item.name}
              </Text>
              <Text size='tiny' secondary>
                {item.description}
              </Text>
            </Box>
          </Link>
        );
      })}
    </Box>
  );
};

export default AccordionMenuItem;
