import { Store } from '.';

export type BISlice = {
  searchSessionId: string;
  setSearchSessionId: (searchSessionId: string) => void;
};

export const getBIInitialState = (set: (stateUpdater: (state: Store) => Partial<Store>) => void
): BISlice => ({
  searchSessionId: '',
  setSearchSessionId: (searchSessionId: string) => {
    set((state: Store) => ({
      biSlice: {
        ...state.biSlice,
        searchSessionId,
      },
    }));
  }
});
