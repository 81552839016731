import { Box } from '@wix/design-system';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';

import { Resource, Topic } from '..';
import HeaderSmallScreenMenu from './HeaderSmallScreenMenu';
import { PRODUCTION_DOMAIN } from '../../../constants';
import { DATA_HOOKS } from '../../../dataHooks';
import useDisableScroll from '../../../hooks/useDisableScroll';
import { InnerLink } from '../../InnerLink';
import { useHeaderBI } from '../bi';
import { SearchBarModal } from '../HeaderRight/SearchBarModal';

import css from './index.module.scss';

type HeaderSmallScreenProps = {
  topics: Topic[];
  resources: Resource[];
};

const HeaderSmallScreen: React.FC<HeaderSmallScreenProps> = ({
  topics,
  resources,
}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { locale } = useRouter();
  const { onLinkClick } = useHeaderBI();

  useDisableScroll(isMenuOpen);
  return (
    <>
      <Box className={css.wrapper} dataHook={DATA_HOOKS.HEADER_SMALL_SCREEN}>
        <Box verticalAlign='middle' gap={2}>
          <Image
            src={`https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/header-small-screen-${isMenuOpen ? 'close' : 'menu'}.svg`}
            alt='small-screen-menu'
            height={24}
            width={24}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            data-hook={DATA_HOOKS.HEADER_SMALL_SCREEN_MENU_BUTTON}
          />
          <Box width='1px' height='20px' backgroundColor='D55' />
          <InnerLink
            href='/'
            className={css.logoCTA}
            onClick={async () => {
              await onLinkClick('Wix Help Center', `https://${PRODUCTION_DOMAIN}/${locale}`);
            }}
            dataHook={DATA_HOOKS.HEADER_LOGO}
          >
            <Image
              src='https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/header-logo.svg'
              alt='header-logo-small-screen'
              loading='eager'
              width={170}
              height={29}
            />
          </InnerLink>
        </Box>
        <SearchBarModal />
      </Box>
      <HeaderSmallScreenMenu
        topics={topics}
        resources={resources}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
    </>
  );
};

export default HeaderSmallScreen;
