

import { Box } from '@wix/design-system';
import React, { FunctionComponent, useContext } from 'react';

import { LoggedIn } from './LoggedIn';
import { NotLoggedIn } from './NotLoggedIn';
import { SearchBarModal } from './SearchBarModal';
import { Context } from '../../../context';


export const HeaderRight: FunctionComponent = () => {
  const { isLoggedInUser } = useContext(Context);
  return (
    <Box gap={2}>
      <SearchBarModal />
      {!isLoggedInUser ? (
        <NotLoggedIn />
      ) : (
        <LoggedIn />
      )}
    </Box>
  );
};
