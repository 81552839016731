import { Avatar, PopoverMenu } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useContext } from 'react';

import { PRODUCTION_DOMAIN } from '../../../../constants';
import { Context } from '../../../../context';
import { DATA_HOOKS } from '../../../../dataHooks';
import { isRTL } from '../../../../utils/internationalization';
import { useHeaderBI } from '../../bi';
import { LanguageMenu } from '../LanguageMenu';

import css from './index.module.scss';

type LoggedInMenuItem = {
  textKey?: string;
  url?: string | ((returnUrl: string) => string);
  languageMenu?: boolean;
};

export const LOGGED_IN_MENU_ITEMS: LoggedInMenuItem[] = [
  {
    textKey: 'header.my-sites',
    url: 'https://www.wix.com/my-account',
  },
  {
    textKey: 'header.account-settings',
    url: 'https://www.wix.com/account/account-settings',
  },
  {
    textKey: 'header.billing-and-payments',
    url: 'https://www.wix.com/wix/api/PaymentConsole',
  },
  {
    textKey: 'header.vouchers',
    url: 'https://www.wix.com/account/vouchers',
  },
  {
    languageMenu: true,
  },
  {
    textKey: 'header.sign-out',
    url: (returnUrl: string) =>
      `https://www.wix.com/signout?redirectTo=https%3A%2F%2Fsupport.wix.com%2Faccount%2Flogout%3FredirectUrl%3D${returnUrl}`,
  },
];

export const LoggedIn: FunctionComponent = () => {
  const { asPath, locale } = useRouter();
  const { t } = useTranslation();
  const { user } = useContext(Context);
  const isRTLLanguage = isRTL(locale);
  const returnUrl = `https://${PRODUCTION_DOMAIN}/${locale}${asPath}`;
  const { onLinkClick } = useHeaderBI();

  const onExternalUrlClick = async (item: LoggedInMenuItem) => {
    const url = typeof item.url === 'function' ? item.url(returnUrl) : item.url as string;
    await onLinkClick(t(item.textKey as string), url);
    window.location.href = url;
  };

  return (
    <PopoverMenu
      className={css.loggedIn}
      placement={isRTLLanguage ? 'bottom-start' : 'bottom-end'}
      dataHook={DATA_HOOKS.HEADER_LOGGED_IN_MENU}
      minWidth={288}
      triggerElement={
        <div data-hook={DATA_HOOKS.HEADER_AVATAR}>
          <Avatar
            color='A1'
            className={css.avatar}
            name={`${user?.firstName} ${user?.lastName}`}
            imgProps={{
              src: user?.img,
            }}
            size='size36'
          />
        </div>
      }
    >
      {LOGGED_IN_MENU_ITEMS.map((item, index) =>
        item.languageMenu ? (
          <PopoverMenu.MenuItem
            disabled
            key='languages-menu'
            onClick={() => false}
            text={<LanguageMenu />}
          />
        ) : (
          <PopoverMenu.MenuItem
            key={index}
            onClick={() => onExternalUrlClick(item)}
            text={t(item.textKey as string)}
          />
        )
      )}
    </PopoverMenu>
  );
};
