import { useExperiments } from '@wix/fe-essentials-standalone';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import { EXPERIMENTS, FEATURE_TOGGLES, LOCALES } from '../constants';
import { Context } from '../context';
import { useWindowSize } from './useWindowSize';
import { isMobile, isTablet } from '../utils/userAgent';
import { useStore } from '../zustand/stores';

export const useIsGenAnswer = () => {
  const { experiments } = useExperiments({ readOnly: true });
  const context = useContext(Context);
  const [isMobileView, setIsMobileView] = useState(context.isMobile);
  const [isTabletView, setIsTabletView] = useState(context.isTablet);
  const featureTogglesSlice = useStore(
    (state) => (state.featureTogglesSlice));

  const isAiServicesDisabled = featureTogglesSlice[FEATURE_TOGGLES.DISABLE_AI_SERVICES];
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width) {
      setIsMobileView(isMobile(context));
      setIsTabletView(isTablet(context));
    }
  }, [context, windowSize.width]);

  const { locale } = useRouter();
  const isGenAnswerEnabled =
    experiments.enabled(EXPERIMENTS.SPECS.NEXT_GEN_SEARCH) &&
    locale === LOCALES.EN &&
    !isAiServicesDisabled &&
    !isMobileView &&
    !isTabletView;

  return isGenAnswerEnabled;
};
