import { Box, Text, TextButton } from '@wix/design-system';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import { ArrowLeft, ArrowRight, ExternalLink } from 'wix-ui-icons-common';

import { MenuItem } from '..';
import { PRODUCTION_DOMAIN } from '../../../../constants';
import { DATA_HOOKS } from '../../../../dataHooks';
import { isRTL } from '../../../../utils/internationalization';
import { InnerLink } from '../../../InnerLink';
import { useHeaderBI } from '../../bi';

import css from './index.module.scss';

export type ExpandableMenuItemProps = {
  item: MenuItem;
  innerLinks: boolean;
  hideMenu: () => void;
  hoveredItem: string | null;
  setHoveredItem: (id: string | null) => void;
};

const ExpandableMenuItem: React.FC<ExpandableMenuItemProps> = ({
  item,
  innerLinks,
  hideMenu,
  hoveredItem,
  setHoveredItem,
}) => {
  const { locale } = useRouter();
  const isRTLLanguage = isRTL(locale);
  const isHovered = hoveredItem === item.id;
  const { onLinkClick } = useHeaderBI();
  const getIcon = () => {
    if (!isHovered) {
      return undefined;
    }
    if (innerLinks) {
      return isRTLLanguage ? <ArrowLeft /> : <ArrowRight />;
    }
    return <ExternalLink />;
  };

  const onClick = async () => {
    hideMenu();
    await onLinkClick(item.name, innerLinks ? `https://${PRODUCTION_DOMAIN}/${locale}${item.uri}` : item.uri, innerLinks ? item.id : undefined);
  };

  const title = (
    <TextButton
      ellipsis
      className={classNames(css.itemName, { [css.hoveredItem]: isHovered })}
      skin='dark'
      weight='bold'
      suffixIcon={getIcon()}
      dataHook={DATA_HOOKS.HEADER_EXPANDABLE_MENU_ITEM_NAME}
      onMouseEnter={() => {
        setHoveredItem(item.id);
      }}
      onMouseLeave={() => {
        setHoveredItem(null);
      }}
    >
      {item.name}
    </TextButton>
  );

  return (
    <Box direction='vertical' gap={1} width='100%'>
      {innerLinks ? (
        <InnerLink
          href={item.uri}
          onClick={onClick}
          dataHook={DATA_HOOKS.HEADER_EXPANDABLE_MENU_ITEM_LINK}
        >
          {title}
        </InnerLink>
      ) : (
        <a
          target='_blank'
          onClick={onClick}
          rel='noreferrer'
          href={item.uri}
          data-hook={DATA_HOOKS.HEADER_EXPANDABLE_MENU_ITEM_LINK}
        >
          {title}
        </a>
      )}
      <Text
        dataHook={DATA_HOOKS.HEADER_EXPANDABLE_MENU_ITEM_DESCRIPTION}
        secondary
      >
        {item.description}
      </Text>
    </Box>
  );
};

export default ExpandableMenuItem;
