import { v4 as uuidv4 } from 'uuid';
import { AUTO_VOTE, COOKIES } from './../constants';
import { PageType } from '../types';

export const redirectToLogin = (locale: string) => {
  const currentUrl = encodeURIComponent(location.href);
  location.href = `https://users.wix.com/signin?postLogin=${currentUrl}&postSignUp=${currentUrl}&redirectUrl=${currentUrl}&locale=${locale}`;
};

export const getRedirectToLoginUrl = (locale: string, autoVote?: boolean) => {
  let currentUrl: string | URL = encodeURIComponent(location.href);

  if (autoVote) {
    currentUrl = new URL(location.href);
    currentUrl.searchParams.set(AUTO_VOTE, 'true');
    currentUrl = encodeURIComponent(currentUrl.toString());
  }

  return `https://users.wix.com/signin?postLogin=${currentUrl}&postSignUp=${currentUrl}&redirectUrl=${currentUrl}&locale=${locale}`;
};

export const loginWithAnswersSSO = (redirectUrl: string, locale: string) => {
  if(!isLocalhost()){
    const base = 'https://support.wix.com/api/v1/accounts/sso?redirectUrl=';
    location.href = base + encodeURIComponent(redirectUrl) + '&lang=' + locale;
  }
};

const isLocalhost = () => {
  return /^localhost$|^127\.0\.0\.1$|^::1$/.test(window.location.hostname);
}

const getCookie = (name: string) => {
  try {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts?.length === 2) {
      return parts?.pop()?.split(';').shift();
    }
  } catch (e) {
    /* Swallow Error */
  }
};

const createCookie = (
  cookieKey: string,
  value: string,
  persistenceMilliseconds?: number
) => {
  try {
    let expires = '';
    if (persistenceMilliseconds) {
      const date = new Date();
      date.setTime(date.getTime() + persistenceMilliseconds);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = `${cookieKey}=${value}${expires}; path=/; domain=.wix.com`;
  } catch (e) {
    /* Swallow Error */
  }
};

export const hasWixClientId = () => !!getCookie(COOKIES.WIX_CLIENT_ID);
export const getClientIdOrGenerate = () =>
  hasWixClientId() ? getCookie(COOKIES.WIX_CLIENT_ID) : uuidv4();
export const setWixClientId = () => {
  const HALF_YEAR_MILLISECONDS = 5 * 365 * 24 * 60 * 60 * 1000;
  createCookie(COOKIES.WIX_CLIENT_ID, uuidv4(), HALF_YEAR_MILLISECONDS);
};
export const getWixLanguage = () => getCookie(COOKIES.WIX_LANGUAGE);

export const hasAnswersSSO = (): boolean =>
  !!getCookie(COOKIES.WIX_ANSWERS_CLIENT_ID);

export const isPersonalPage = (pageType: PageType) =>
  [PageType.Profile, PageType.Ticket].includes(pageType);
