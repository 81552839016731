import { Box, TextButton } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'wix-ui-icons-common';

import { ExpandableMenuState } from '..';
import { BI, LOCALES, PRODUCTION_DOMAIN } from '../../../constants';
import { Context } from '../../../context';
import { DATA_HOOKS } from '../../../dataHooks';
import { InnerLink } from '../../InnerLink';
import { useHeaderBI } from '../bi';

import css from './index.module.scss';

type HeaderLeftProps = {
  expandableMenuState: ExpandableMenuState;
  setExpandableMenuState: (state: ExpandableMenuState) => void;
};

export const HeaderLeft: FunctionComponent<HeaderLeftProps> = ({
  expandableMenuState,
  setExpandableMenuState,
}) => {
  const router = useRouter();
  const { isLoggedInUser, isWixStudioUser } = useContext(Context);
  const { t } = useTranslation();
  const { onLinkClick, onItemView } = useHeaderBI();
  const showTopics = expandableMenuState === ExpandableMenuState.TOPICS;
  const showResources = expandableMenuState === ExpandableMenuState.RESOURCES;

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      void onItemView(isWixStudioUser ? BI.ITEM_NAMES.HEADER_ROADMAP : BI.ITEM_NAMES.HEADER_PRICING);
    };
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [isWixStudioUser, onItemView, router, t]);

  useEffect(() => {
    void onItemView(isWixStudioUser ? BI.ITEM_NAMES.HEADER_ROADMAP : BI.ITEM_NAMES.HEADER_PRICING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Box gap={8}>
      <InnerLink
        href='/'
        className={css.logoCTA}
        dataHook={DATA_HOOKS.HEADER_LOGO}
        onClick={async () => {
          await onLinkClick('Wix Help Center', `https://${PRODUCTION_DOMAIN}/${router.locale}`);
        }}
      >
        <Image
          src='https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/header/header-logo.svg'
          alt='header-logo'
          width={170}
          height={29}
          loading='eager'
        />
      </InnerLink>

      <Box gap={3} verticalAlign='middle'>
        <TextButton
          skin='dark'
          weight={showTopics ? 'bold' : 'thin'}
          onClick={() => {
            setExpandableMenuState(
              showTopics ? ExpandableMenuState.NONE : ExpandableMenuState.TOPICS
            );
          }}
          suffixIcon={showTopics ? <ChevronUp /> : <ChevronDown />}
          className={css.textButton}
          dataHook={DATA_HOOKS.HEADER_TOPICS}
        >
          {t('header.topics')}
        </TextButton>
        <TextButton
          skin='dark'
          weight={showResources ? 'bold' : 'thin'}
          onClick={() => {
            setExpandableMenuState(
              showResources
                ? ExpandableMenuState.NONE
                : ExpandableMenuState.RESOURCES
            );
          }}
          suffixIcon={showResources ? <ChevronUp /> : <ChevronDown />}
          className={css.textButton}
          dataHook={DATA_HOOKS.HEADER_RESOURCES}
        >
          {t('header.resources')}
        </TextButton>
        {isLoggedInUser && isWixStudioUser && (
          <InnerLink
            href='/roadmap'
            locale={LOCALES.EN}
            dataHook={DATA_HOOKS.HEADER_PRODUCT_ROADMAP}
            onClick={async () => {
              await onLinkClick(t('header.product-roadmap'), `https://${PRODUCTION_DOMAIN}/${LOCALES.EN}/roadmap`);
            }}
          >
            <TextButton skin='dark' className={css.textButton}>
              {t('header.product-roadmap')}
            </TextButton>
          </InnerLink>
        )}
        {!isWixStudioUser && (
          <>
            <Box width='1px' height='20px' backgroundColor='D55' />
            <a
              href='https://www.wix.com/plans'
              target='_blank'
              rel='noreferrer'
              data-hook={DATA_HOOKS.HEADER_PRICING}
              onClick={async () => {
                await onLinkClick(t('header.pricing'), 'https://www.wix.com/plans');
              }}
            >
              <TextButton skin='dark' className={css.textButton}>
                {t('header.pricing')}
              </TextButton>
            </a>
          </>
        )}
      </Box>
    </Box>
  );
};
