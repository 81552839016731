import { abTranslate } from '@wix/ab-translate/browser';
import { User } from '@wix/ambassador-identity-account-v2-account/build/cjs/types.impl';
import { Article, Category } from '@wix/answers-api';
import { WixDesignSystemProvider } from '@wix/design-system';
import { createEssentials } from '@wix/fe-essentials-standalone';
import { RootEssentialsProvider } from '@wix/fe-essentials-standalone/react';
import type { ExperimentsBag } from '@wix/wix-experiments';
import { I18nextProvider, initI18n, Messages } from '@wix/wix-i18n-config';
import moment from 'moment';
import App, { AppContext, AppProps } from 'next/app';
import Script from 'next/script';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Error } from '../components/Error';
import { GlobalLoader } from '../components/GlobalLoader';
import Header from '../components/Header';
import { NotificationBar } from '../components/NotificationBar';
import { PageTemplate, PageTemplateData } from '../components/PageTemplate';
import { HEADER_FOOTER_SUPPORT_LANGUAGES, LOCALES } from '../constants';
import { Context, answersApi } from '../context';
import HistoryProvider from '../hooks/useRouteHistory';
import { fetchSessionBI } from '../hooks/useSessionBI';
import { PageType, PageSeo, SessionBI, SessionBIType, SiteSelectorData, SiteMetaData } from '../types';
import { sessionDebounceBI } from '../utils/bi';
import {
  hasAnswersSSO,
  isPersonalPage,
  loginWithAnswersSSO,
} from '../utils/login';
import '../styles/globals.scss';
import '@wix/cc-user-facing-common-components/src/style.scss';
import '@wix/design-system/styles.global.css';
import 'normalize.css/normalize.css';
import StoreProvider from '../zustand/store-provider';
import { Store } from '../zustand/stores';

type PageData = {
  article?: Article;
  category?: Category;
};

type PageProps = {
  pageData: PageData;
  pageType: PageType;
  itemId?: string;
  itemType?: string;
  isMobile: boolean;
  isTablet: boolean;
  experiments: ExperimentsBag;
  pageSeo: PageSeo;
  hasError?: boolean;
  messageError?: string;
  statusCode?: number;
  user?: User;
  siteSelectorData?: SiteSelectorData;
  loggedInAccountId?: string;
  initialStoreState: Store;
};

type CustomAppProps = AppProps & {
  locale: string;
  messages: Messages;
  experiments: ExperimentsBag;
  pageProps: PageProps;
};

const CustomApp = ({
  Component,
  pageProps,
  locale,
  messages,
}: CustomAppProps) => {
  const {
    pageType,
    itemType,
    isMobile,
    initialStoreState,
    experiments,
    pageSeo,
    hasError,
    messageError,
    statusCode,
    itemId,
    pageData,
    isTablet,
    isLoggedInUser,
    isWixStudioUser,
    isWixEmployee,
    user,
    loggedInAccountId,
    userSitesResponse
  } = pageProps;
  const i18n = initI18n({
    locale,
    messages: experiments ? abTranslate(experiments, messages) : messages,
  });

  const fetchSessionDebounceBI = sessionDebounceBI(fetchSessionBI, 300);

  const first = useRef(true);

  const [pageTemplateData, setPageTemplateData] = useState<PageTemplateData>({
    pageData,
    itemId,
    itemType,
    hasError: hasError || false,
  });

  const [siteSelectorData, setSiteSelectorData] = useState<SiteSelectorData>({
    ...userSitesResponse,
    selectedSite: userSitesResponse?.lastInteractedSite || userSitesResponse?.sites?.[0],
  });

  const [sessionBI, setSessionBI] = useState<SessionBI>({
    lastSessionDate: moment(),
    selfServiceSessionId: null,
  });

  const [showSearchModal, setShowSearchModal] = useState(false);

  const setUserSelectedSite = (site?: SiteMetaData | null) => {
    setSiteSelectorData({
      ...siteSelectorData,
      selectedSite: site,
    });
  };

  const essentials = createEssentials({
    environment: {
      language: locale,
      artifactId: 'kb-client-nextjs',
      version: '0.1.0',
    },
    experiments: { bag: pageProps.experiments },
    fedops: {},
    httpClient: {
      baseURL: `/${locale}/`,
    },
  });

  useEffect(() => {
    const userIsLoggedInToWixOnly = isLoggedInUser && !hasAnswersSSO();
    if (isLoginRequired(pageType, isLoggedInUser) || userIsLoggedInToWixOnly) {
      loginWithAnswersSSO(location.href, locale);
    }
    if (!isLoggedInUser) {
      localStorage.setItem('hc_session_bi_type', SessionBIType.VISITOR);
    }
  }, [isLoggedInUser, locale, pageType]);

  useEffect(() => {
    if (first.current) {
      first.current = false;
      return;
    }
    setPageTemplateData({
      pageData,
      itemId,
      itemType,
      hasError: hasError || false,
    });
  }, [pageData, itemId, itemType, hasError]);

  const thirdPartyScripts = useMemo(() => {
    if (globalThis?.location && location.hostname === 'localhost') {
      return null;
    }

    const integrationLocale = HEADER_FOOTER_SUPPORT_LANGUAGES.includes(
      locale as string
    )
      ? locale
      : LOCALES.EN;

    return (
      <>
        <Script src="https://apps.wix.com/answers-wix-integration/cookie-policy/consent-manager.js" />
        <Script src="https://apps.wix.com/answers-wix-integration/cookie-policy/tag-manager.js" />
        <Script
          src={`https://apps.wix.com/answers-wix-integration/cookie-policy/${integrationLocale}/setup.js`}
          strategy="lazyOnload"
        />
      </>
    );
  }, [locale, globalThis?.location?.hostname]);
  return (
    <StoreProvider {...initialStoreState}>
      <Context.Provider
        value={{
          answersApi,
          isMobile,
          isTablet,
          experiments,
          isLoggedInUser,
          isWixStudioUser,
          pageTemplateData,
          isWixEmployee,
          setPageTemplateData,
          pageType,
          sessionBI,
          setSessionBI,
          fetchSessionDebounceBI,
          user,
          loggedInAccountId,
          setUserSelectedSite,
          siteSelectorData,
          showSearchModal,
          setShowSearchModal
        }}
      >
        <HistoryProvider>
          <RootEssentialsProvider essentials={essentials}>
            <I18nextProvider i18n={i18n}>
              <WixDesignSystemProvider features={{ newColorsBranding: true }}>
                <Header />
                <NotificationBar pageType={pageType} />
                <PageTemplate pageType={pageType} pageSeo={pageSeo}>
                  <GlobalLoader />
                  {hasError && (
                    <Error
                      statusCode={statusCode as number}
                      messageError={messageError}
                    />
                  )}
                  {!hasError && isLoginRequired(pageType, isLoggedInUser) && <></>}
                  {!hasError && !isLoginRequired(pageType, isLoggedInUser) && (
                    <Component {...pageProps} />
                  )}
                </PageTemplate>
                {thirdPartyScripts}
              </WixDesignSystemProvider>
            </I18nextProvider>
          </RootEssentialsProvider>
        </HistoryProvider>
      </Context.Provider>
    </StoreProvider>
  );
};

CustomApp.getInitialProps = async (context: AppContext) => {
  let { locale } = context.router;
  if (!Object.values(LOCALES).includes(locale as string)) {
    locale = LOCALES.EN;
  }
  const { pageProps } = await App.getInitialProps(context);
  const messages = await import(`../locales/messages_${locale}.json`);
  return { pageProps, locale, messages };
};

const isLoginRequired = (pageType: PageType, isLoggedInUser?: boolean) =>
  isPersonalPage(pageType) && (!isLoggedInUser || !hasAnswersSSO());

export default CustomApp;
