import { Box, TextButton, TextButtonSize } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import classNames from 'classnames';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { ChevronDownSmall } from 'wix-ui-icons-common';


import { SiteSelectorDropDown } from './SiteSelectorDropDown';
import { Context } from '../../context';
import { DATA_HOOKS } from '../../dataHooks';

import css from './index.module.scss';

export enum SiteSelectorParent {
  Hero = 'Hero',
  SearchBarModal = 'SearchBarModal',
}

type SiteSelectorProps = {
  itemId?: string;
  parent: SiteSelectorParent;
  relativeText?: string;
  parentElm?: HTMLElement | null;
};

export const SiteSelector: FC<SiteSelectorProps> = ({
  itemId,
  parent,
  relativeText,
  parentElm,
}) => {
  const { t } = useTranslation();
  const { siteSelectorData } = useContext(Context);
  const { selectedSite } = siteSelectorData;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [moveArrowTo, setMoveArrowTo] = useState<number>();

  const closePopover = () => setIsPopoverOpen(false);
  const onToggle = () => setIsPopoverOpen(!isPopoverOpen);

  useEffect(() => {
    if (parent === SiteSelectorParent.SearchBarModal) {
      const siteNameElm = document.querySelector(
        `[data-hook="${DATA_HOOKS.SEARCH_BAR_MODAL_BODY}"] [data-hook="${DATA_HOOKS.SITE_SELECTOR_DROPDOWN_BUTTON}"]`
      );
      if (siteNameElm) {
        const siteNameRect = siteNameElm.getBoundingClientRect();
        const parentRect = parentElm?.getBoundingClientRect();
        if (parentRect && siteNameRect) {
          setMoveArrowTo(
            siteNameRect.x - parentRect.x + siteNameRect.width / 2
          );
        }
      }
    }
  }, [parentElm, selectedSite]);

  const SiteDisplayName = useMemo(() => {
    if (selectedSite) {
      return selectedSite.siteProperties.siteDisplayName;
    } else {
      return t('site-selector.select-a-site');
    }
  }, [selectedSite, t]);

  if (!siteSelectorData.itemsCount) {
    return null;
  }

  const getSiteSelectorStyle = () => {
    switch (parent) {
      case SiteSelectorParent.Hero:
        return { textSize: 'medium', moveBy: { y: 10 }, width: '450px' };
      case SiteSelectorParent.SearchBarModal:
        // need to calc the move by according to the length of the site name.
        return { textSize: 'small', width: '536px' };
      default:
        return {};
    }
  };

  const { textSize, width, moveBy } = getSiteSelectorStyle();

  return (
    <SiteSelectorDropDown
      shown={isPopoverOpen}
      onToggle={onToggle}
      onClickOption={closePopover}
      itemId={itemId}
      width={width}
      moveBy={moveBy}
      moveArrowTo={moveArrowTo}
    >
      <Box
        width={parentElm?.getBoundingClientRect().width}
        style={{ display: 'inline-block' }}
      >
        {relativeText}
        <TextButton
          size={textSize as TextButtonSize}
          weight="bold"
          className={classNames(css.SiteSelectorPopoverOpenButton, {
            [css.SiteSelectorPopoverOpenButtonOpen]: isPopoverOpen,
          })}
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          ellipsis
          suffixIcon={<ChevronDownSmall className={css.chevronDownIcon} />}
          dataHook={DATA_HOOKS.SITE_SELECTOR_DROPDOWN_BUTTON}
        >
          {SiteDisplayName}
        </TextButton>
      </Box>
    </SiteSelectorDropDown>
  );
};
