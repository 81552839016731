import {
  Box,
  IconButton,
  Badge,
  IconElement,
  Text,
  Input,
} from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import {
  ArrowRight as ArrowRightIcon,
  HintSmall as HintSmallIcon,
  AppSmall as AppSmallIcon,
  OnlineProgramsSmall as OnlineProgramsSmallIcon,
  PremiumSmall as PremiumSmallIcon,
  GoogleAdsSmall as GoogleAdsIcon,
  EmailSmall as EmailSmallIcon,
  SparklesFilled as SparklesFilledIcon,
  Search as SearchIcon,
} from '@wix/wix-ui-icons-common';
import classNames from 'classnames';
import React, { FunctionComponent, useContext, useRef, useState } from 'react';

import { Context } from '../../../../../context';
import { DATA_HOOKS } from '../../../../../dataHooks';
import { useIsGenAnswer } from '../../../../../hooks/useIsGenAnswer';
import { useIsSitePickerEnabled } from '../../../../../hooks/useIsSitePickerEnabled';
import { MAX_SEARCH_INPUT_LENGTH } from '../../../../KnowledgeAgentSearchHero';
import { SiteSelector, SiteSelectorParent } from '../../../../SiteSelector';

import css from './index.module.scss';

export type SearchBarModalBodyProps = {
  goToSearchResultsPage: (overideQuery?: string) => Promise<void>;
  query: string;
  setQuery: (query: string) => void;
};

type SearchBadge = {
  key: string;
  icon: IconElement;
};

export const BADGES: SearchBadge[] = [
  {
    key: 'search-bar-modal.badge.connect-a-domain',
    icon: <OnlineProgramsSmallIcon />,
  },
  {
    key: 'search-bar-modal.badge.choose-a-premium-plan',
    icon: <PremiumSmallIcon />,
  },
  {
    key: 'search-bar-modal.badge.create-a-dynamic-page',
    icon: <AppSmallIcon />,
  },
  {
    key: 'search-bar-modal.badge.run-google-ads-with-wix',
    icon: <GoogleAdsIcon />,
  },
  {
    key: 'search-bar-modal.badge.connect-an-external-email',
    icon: <EmailSmallIcon />,
  },
];

export const SearchBarModalBody: FunctionComponent<SearchBarModalBodyProps> = ({
  goToSearchResultsPage,
  query,
  setQuery,
}) => {
  const {
    pageTemplateData: { itemId },
  } = useContext(Context);
  const [hoveredBadgeIndex, setHoveredBadgeIndex] = useState(-1);
  const { t } = useTranslation();
  const isSitePickerEnabled = useIsSitePickerEnabled();
  const { isMobile, isLoggedInUser, siteSelectorData } = useContext(Context);
  const isGenAnswerEnabled = useIsGenAnswer();

  const showSitePicker =
    isSitePickerEnabled && isLoggedInUser && siteSelectorData?.itemsCount;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Box direction="vertical" className={css.searchBarModalBody}>
      <Box
        ref={containerRef}
        direction="vertical"
        gap="SP1"
        className={css.searchBarModalContent}
      >
        {showSitePicker && (
          <Text size="small">
            <SiteSelector
              itemId={itemId}
              parent={SiteSelectorParent.SearchBarModal}
              parentElm={containerRef.current}
              relativeText={t('search-bar-modal.site-selector.title')}
            />
          </Text>
        )}
        <Box
          direction="horizontal"
          gap="SP1"
          verticalAlign="middle"
          width="100%"
          marginTop={isSitePickerEnabled ? 'SP1' : '0'}
        >
          <Box
            direction="horizontal"
            className={css.searchWrapper}
            gap="SP1"
            width="100%"
          >
            <Input
              placeholder={t(
                `search-bar-modal.placeholder${isMobile ? '-mobile' : ''}`
              )}
              onChange={(event) => setQuery(event.target.value)}
              size="large"
              onClear={() => setQuery('')}
              value={query}
              onEnterPressed={() => goToSearchResultsPage()}
              dataHook={DATA_HOOKS.SEARCH_BAR_MODAL_INPUT}
              className={classNames(css.searchInput, {
                [css.innerButton]: isGenAnswerEnabled,
              })}
              maxLength={MAX_SEARCH_INPUT_LENGTH}
              autoFocus
              prefix={
                <Input.IconAffix>
                  {isGenAnswerEnabled ? <SparklesFilledIcon /> : <SearchIcon />}
                </Input.IconAffix>
              }
              suffix={
                isGenAnswerEnabled && (
                  <Input.IconAffix>
                    <IconButton
                      size={isMobile ? 'tiny' : 'large'}
                      skin="ai"
                      onClick={() => goToSearchResultsPage()}
                      dataHook={DATA_HOOKS.SEARCH_BAR_MODAL_CTA}
                      className={css.searchCTA_AI}
                    >
                      <ArrowRightIcon />
                    </IconButton>
                  </Input.IconAffix>
                )
              }
            />
            {!isGenAnswerEnabled && (
              <IconButton
                size={isMobile ? 'tiny' : 'large'}
                onClick={() => goToSearchResultsPage()}
                dataHook={DATA_HOOKS.SEARCH_BAR_MODAL_CTA}
                className={css.searchCTA}
              >
                <ArrowRightIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box
          direction="horizontal"
          align="center"
          className={css.searchBarModalHint}
          gap={isMobile ? '0' : '3px'}
        >
          {!isMobile && <HintSmallIcon />}
          <Text size={isMobile ? 'tiny' : 'small'}>
            {t('search-bar-modal.hint')}
          </Text>
        </Box>
      </Box>
      <Box direction="horizontal" className={css.searchBarModalBadges}>
        {BADGES.map((badge: SearchBadge, index: number) => (
          <div
            className={css.searchBarModalBadge}
            key={badge.key}
            onMouseEnter={() => setHoveredBadgeIndex(index)}
            onMouseLeave={() => setHoveredBadgeIndex(-1)}
            onClick={() => goToSearchResultsPage(t(badge.key))}
            data-hook={DATA_HOOKS.SEARCH_BAR_MODAL_BADGE}
            tabIndex={0}
          >
            <Badge
              skin={index === hoveredBadgeIndex ? 'general' : 'neutralLight'}
              prefixIcon={badge.icon}
            >
              {t(badge.key)}
            </Badge>
          </div>
        ))}
      </Box>
    </Box>
  );
};
