import { Box, Divider, Popover, Search, TextButton } from '@wix/design-system';
import { useHttpClient } from '@wix/fe-essentials-standalone';
import { useTranslation } from '@wix/wix-i18n-config';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import { Context } from '../../../context';
import { DATA_HOOKS } from '../../../dataHooks';
import { GetUserSitesResponse } from '../../../services/user/sites';
import { SiteMetaData } from '../../../types';
import { SiteSelectorList } from '../SiteSelectorList';
import {
  SiteSelectorState,
  SiteSelectorStateType,
} from '../SiteSelectorStates';
import { useSiteSelectorBI } from './bi';
import { SEARCH_DEBOUNCE } from '../../../constants';

export type SiteSelectorProps = {
  shown: boolean;
  children: JSX.Element;
  onToggle: () => void;
  onClickOption: (site?: SiteMetaData) => void;
  itemId?: string;
  width?: string;
  moveBy?: { x?: number; y?: number };
  moveArrowTo?: number;
};

export const MAX_QUERY_LENGTH = 100;

export const SiteSelectorDropDown = ({
  shown,
  onToggle,
  onClickOption,
  children,
  itemId,
  width,
  moveBy,
  moveArrowTo,
}: SiteSelectorProps) => {
  const { siteSelectorData, setUserSelectedSite } = useContext(Context);
  const [cursor, setCursor] = useState<string | undefined>(
    siteSelectorData.cursor
  );
  const { sites: initialSites, itemsCount, selectedSite } = siteSelectorData;
  const [sites, setSites] = useState<SiteMetaData[]>(initialSites);
  const [updatedItemsCount, setUpdatedItemsCount] = useState<number>(
    itemsCount || 0
  );
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [errorOrEmptyState, setErrorOrEmptyState] =
    useState<SiteSelectorStateType | null>(null);
  const { t } = useTranslation();
  const httpClient = useHttpClient();
  const bi = useSiteSelectorBI(v4(), itemId);

  const fetchSites = useCallback(
    async (options?: { clear?: boolean; term?: string; page?: number }) => {
      setErrorOrEmptyState(null);
      if (options?.clear && options?.term === '') {
        setSites(siteSelectorData.sites);
        setCursor(siteSelectorData.cursor);
        setUpdatedItemsCount(itemsCount as number);
        return;
      }
      const shouldClear = options?.clear || options?.term;
      let nextCursor = cursor;
      if (shouldClear) {
        nextCursor = '';
      }

      const { data } = await httpClient.get<GetUserSitesResponse>(
        '/api/user/sites',
        {
          params: { term: options?.term, cursor: nextCursor },
        }
      );

      if (options?.clear && data.sites.length === 0) {
        setErrorOrEmptyState(SiteSelectorStateType.EMPTY);
        return;
      }

      const updatedSites = shouldClear ? data.sites : [...sites, ...data.sites];
      setSites(updatedSites);
      setUpdatedItemsCount(data.itemsCount || 0);
      setCursor(data.cursor);
    },
    [httpClient, siteSelectorData.sites, cursor]
  );

  const onSearch = (term: string) => {
    setQuery(term);
  };

  const onClearSearch = () => {
    onSearch('');
    setErrorOrEmptyState(null);
  };

  useEffect(() => {
    void (async () => {
      try {
        setLoading(true);
        await fetchSites({ clear: true, term: query });
      } catch (e) {
        setErrorOrEmptyState(SiteSelectorStateType.ERROR);
      } finally {
        setLoading(false);
      }
    })();
  }, [query]);

  useEffect(() => {
    onClearSearch();
  }, [shown]);

  const textButtonLabel = useMemo(() => {
    if (errorOrEmptyState === SiteSelectorStateType.ERROR) {
      return t('site-selector-modal.refresh');
    }
    if (errorOrEmptyState === SiteSelectorStateType.EMPTY || query.length) {
      return t('site-selector-modal.clear-search');
    }
    return t('site-selector-modal.clear-selection');
  }, [errorOrEmptyState, query.length]);

  const onSiteItemClick = (site: SiteMetaData) => {
    const previousSelectedSiteId = selectedSite?.metaSiteId || undefined;
    setUserSelectedSite(site);
    onClickOption(site);
    bi.onClickSiteOrAllSites(previousSelectedSiteId, site.metaSiteId);
  };

  const onClearSelection = () => {
    const previousSelectedSiteId = selectedSite?.metaSiteId || undefined;
    setUserSelectedSite(null);
    onClickOption();
    bi.onClickSiteOrAllSites(previousSelectedSiteId);
  };

  if (!siteSelectorData || !siteSelectorData.sites) {
    return null;
  }

  const isTextButtonDisabled =
    selectedSite === null && !query && !errorOrEmptyState;

  const onClickTextButton = async () => {
    if (errorOrEmptyState === SiteSelectorStateType.ERROR) {
      try {
        setLoading(true);
        await fetchSites({ clear: true, term: query });
      } catch (e) {
        setErrorOrEmptyState(SiteSelectorStateType.ERROR);
      } finally {
        setLoading(false);
      }
    } else if (query.length) {
      onClearSearch();
    } else {
      onClearSelection();
    }
  };

  return (
    <Popover
      placement="bottom"
      dataHook={DATA_HOOKS.SITE_SELECTOR_POPOVER}
      onClickOutside={onToggle}
      showArrow
      moveArrowTo={moveArrowTo}
      moveBy={moveBy}
      shown={shown}
      width={width}
      appendTo="window"
      animate
    >
      <Popover.Element>{children}</Popover.Element>
      <Popover.Content>
        <Box
          direction="vertical"
          verticalAlign="space-between"
          height="100%"
          boxSizing="border-box"
        >
          <Box
            verticalAlign="middle"
            boxSizing="border-box"
            padding="2 4"
            height="60px"
          >
            <Search
              size="small"
              placeholder={t('site-selector-modal.search')}
              onClear={onClearSearch}
              dataHook={DATA_HOOKS.SITE_SELECTOR_SEARCH}
              onChange={(e) => onSearch(e.target.value)}
              value={query}
              status={loading ? 'loading' : undefined}
              debounceMs={SEARCH_DEBOUNCE}
              maxLength={MAX_QUERY_LENGTH}
            />
          </Box>
          {errorOrEmptyState ? (
            <SiteSelectorState type={errorOrEmptyState} query={query} />
          ) : (
            <SiteSelectorList
              sites={sites}
              currentSiteId={selectedSite?.metaSiteId}
              onSiteItemClick={onSiteItemClick}
              totalSites={updatedItemsCount}
              fetchMoreSites={fetchSites}
            />
          )}
          <Divider />
          <Box verticalAlign="middle" align="center" height="67px">
            <TextButton
              weight="thin"
              size="medium"
              dataHook={DATA_HOOKS.SITE_SELECTOR_TEXT_BUTTON}
              onClick={onClickTextButton}
              disabled={isTextButtonDisabled}
            >
              {textButtonLabel}
            </TextButton>
          </Box>
        </Box>
      </Popover.Content>
    </Popover>
  );
};
