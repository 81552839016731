import { useExperiments } from '@wix/fe-essentials-standalone';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { EXPERIMENTS, LOCALES } from '../constants';
import { Context } from '../context';
import { useIsKnowledgeAgentSearchEnabled } from './useIsKnowledgeAgentSearchEnabled';
import { useStore } from '../zustand/stores';

const EXPANDED_LOCALES = [
  LOCALES.EN,
  // LOCALES.FR, // not translated yet
  // LOCALES.ES,
  // LOCALES.PT,
  // LOCALES.JA,
  // LOCALES.DE, // not translated yet
];

export const useIsSitePickerEnabled = () => {
  const { locale } = useRouter();
  const { experiments } = useExperiments({ readOnly: true });
  const { isMobile, isTablet } = useContext(Context);
  const { isAiServicesDisabled } = useStore((state) => state.featureTogglesSlice);
  const isKnowledgeAgentSearchEnabled = useIsKnowledgeAgentSearchEnabled();

  if (!EXPANDED_LOCALES.includes(locale as string) || isAiServicesDisabled) {
    return false;
  }

  if (isMobile || isTablet) {
    return false;
  }

  return (
    experiments.enabled(EXPERIMENTS.SPECS.SITE_PICKER) &&
    isKnowledgeAgentSearchEnabled
  );
};
