import { Accordion, Box, Button, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'wix-ui-icons-common';

import { Resource, Topic } from '../..';
import AccordionMenuItem, { AccordionMenuItemType } from './AccordionMenuItem';
import LanguagesAccordion from './LanguagesAccordion';
import Profile from './Profile';
import { BI, LOCALES, PRODUCTION_DOMAIN } from '../../../../constants';
import { Context } from '../../../../context';
import { DATA_HOOKS } from '../../../../dataHooks';
import { useHeaderBI } from '../../bi';

import css from './index.module.scss';

export type HeaderSmallScreenMenuProps = {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  topics: Topic[];
  resources: Resource[];
};

const HeaderSmallScreenMenu: React.FC<HeaderSmallScreenMenuProps> = ({
  topics,
  resources,
  isMenuOpen,
  setIsMenuOpen,
}: HeaderSmallScreenMenuProps) => {
  const { t } = useTranslation();
  const { locale, asPath } = useRouter();
  const { isLoggedInUser, isWixStudioUser } = useContext(Context);
  const { onLinkClick, onItemView } = useHeaderBI();
  const [isResourcesExpanded, setIsResourcesExpanded] = React.useState(false);
  const [isTopicsExpanded, setIsTopicsExpanded] = React.useState(false);
  const returnUrl = `https://${PRODUCTION_DOMAIN}/${locale}${asPath}`;
  const loginUrl = `https://users.wix.com/signin?view=sign-up&sendEmail=true&loginCompName=SignUp_Mobile&referralInfo=SignUp_Mobile&postSignUp=${returnUrl}&postLogin=${returnUrl}&loginDialogContext=login&originUrl=${returnUrl}&forceRender=true`;
  const signupUrl = `https://users.wix.com/signin?view=sign-up&sendEmail=true&loginCompName=SignUp_Mobile&referralInfo=SignUp_Mobile&postSignUp=${returnUrl}&postLogin=${returnUrl}&loginDialogContext=signup&originUrl=${returnUrl}`;

  useEffect(() => {
    if (isMenuOpen) {
      void onItemView(isWixStudioUser ? BI.ITEM_NAMES.HEADER_ROADMAP : BI.ITEM_NAMES.HEADER_PRICING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  useEffect(() => {
    if (isResourcesExpanded) {
      void onItemView(isWixStudioUser ? BI.ITEM_NAMES.HEADER_RESOURCES_STUDIO : BI.ITEM_NAMES.HEADER_RESOURCES_DIY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResourcesExpanded, isWixStudioUser]);

  return isMenuOpen ? (
    <Box
      direction='vertical'
      className={css.wrapper}
      dataHook={DATA_HOOKS.HEADER_SMALL_SCREEN_MENU}
    >
      {isLoggedInUser && (
        <>
          <Profile />
          <a
            href='https://www.wix.com/my-account'
            rel='noreferrer'
            className={css.externalLink}
            data-hook={DATA_HOOKS.HEADER_SMALL_SCREEN_MENU_LINK}
            onClick={async () => {
              await onLinkClick(
                t('header.my-sites'),
                'https://www.wix.com/my-account'
              );
            }}
          >
            {t('header.my-sites')}
          </a>
        </>
      )}
      <Accordion
        size='medium'
        multiple
        hideShadow
        dataHook={DATA_HOOKS.HEADER_ACCORDION}
        items={[
          {
            open: isTopicsExpanded,
            onToggle: () => {
              setIsTopicsExpanded(!isTopicsExpanded);
            },
            title: (
              <Text
                className={classNames({ [css.expanded]: isTopicsExpanded })}
              >
                {t('header.topics')}
              </Text>
            ),
            children: (
              <AccordionMenuItem
                items={topics}
                type={AccordionMenuItemType.TOPICS}
                setIsMenuOpen={setIsMenuOpen}
              />
            ),
            buttonType: 'node',
            showLabel: 'always',
            expandLabel: <ChevronDown />,
            collapseLabel: <ChevronUp />,
          },
          {
            open: isResourcesExpanded,
            onToggle: () => {
              setIsResourcesExpanded(!isResourcesExpanded);
            },
            title: (
              <Text
                className={classNames({ [css.expanded]: isResourcesExpanded })}
              >
                {t('header.resources')}
              </Text>
            ),
            children: (
              <AccordionMenuItem
                items={resources}
                type={AccordionMenuItemType.RESOURCES}
                setIsMenuOpen={setIsMenuOpen}
              />
            ),
            buttonType: 'node',
            showLabel: 'always',
            expandLabel: <ChevronDown />,
            collapseLabel: <ChevronUp />,
          },
        ]}
      />
      {isLoggedInUser && isWixStudioUser && (
        <a
          href={`${LOCALES.EN}/roadmap`}
          rel='noreferrer'
          className={classNames(css.externalLink, css.divider)}
          data-hook={DATA_HOOKS.HEADER_SMALL_SCREEN_MENU_LINK}
          onClick={async () => {
            await onLinkClick(
              t('header.product-roadmap'),
              `https://${PRODUCTION_DOMAIN}/${LOCALES.EN}/roadmap`
            );
          }}
        >
          {t('header.product-roadmap')}
        </a>
      )}
      {!isWixStudioUser && (
        <a
          href='https://www.wix.com/plans'
          rel='noreferrer'
          className={css.externalLink}
          data-hook={DATA_HOOKS.HEADER_SMALL_SCREEN_MENU_LINK}
          onClick={async () => {
            await onLinkClick(t('header.pricing'), 'https://www.wix.com/plans');
          }}
        >
          {t('header.pricing')}
        </a>
      )}
      {isLoggedInUser && (
        <a
          href='https://www.wix.com/mobile/wix-app'
          rel='noreferrer'
          className={css.externalLink}
          data-hook={DATA_HOOKS.HEADER_SMALL_SCREEN_MENU_LINK}
          onClick={async () => {
            await onLinkClick(
              t('header.mobile-app'),
              'https://www.wix.com/mobile/wix-app'
            );
          }}
        >
          {t('header.mobile-app')}
        </a>
      )}
      <LanguagesAccordion />
      {!isLoggedInUser && (
        <Box className={css.loginWrapper}>
          <Box gap={3} maxWidth={430} width={'100%'}>
            <Button
              skin='dark'
              priority='secondary'
              size='medium'
              fullWidth
              href={loginUrl}
              dataHook={DATA_HOOKS.HEADER_SMALL_SCREEN_MENU_LOGIN_BUTTON}
              onClick={async () => {
                await onLinkClick(t('header.log-in'), loginUrl);
                location.href = loginUrl;
              }}
            >
              {t('header.log-in')}
            </Button>
            <Button
              skin='dark'
              size='medium'
              fullWidth
              href={signupUrl}
              dataHook={DATA_HOOKS.HEADER_SMALL_SCREEN_MENU_GET_STARTED_BUTTON}
              onClick={async () => {
                await onLinkClick(t('header.get-started'), signupUrl);
                location.href = signupUrl;
              }}
            >
              {t('header.get-started')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  ) : null;
};
export default HeaderSmallScreenMenu;
