

import { Box, Button, TextButton } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';

import { PRODUCTION_DOMAIN } from '../../../../constants';
import { DATA_HOOKS } from '../../../../dataHooks';
import { useHeaderBI } from '../../bi';
import { LanguageMenu } from '../LanguageMenu';

import css from './index.module.scss';

export const NotLoggedIn: FunctionComponent = () => {
  const { asPath, locale } = useRouter();
  const { t } = useTranslation();
  const { onLinkClick } = useHeaderBI();

  const returnUrl = `https://${PRODUCTION_DOMAIN}/${locale}${asPath}`;
  const loginUrl = `https://users.wix.com/signin?view=sign-up&sendEmail=true&loginCompName=SignUp_Mobile&referralInfo=SignUp_Mobile&postSignUp=${returnUrl}&postLogin=${returnUrl}&loginDialogContext=login&originUrl=${returnUrl}&forceRender=true`;
  const signupUrl = `https://users.wix.com/signin?view=sign-up&sendEmail=true&loginCompName=SignUp_Mobile&referralInfo=SignUp_Mobile&postSignUp=${returnUrl}&postLogin=${returnUrl}&loginDialogContext=signup&originUrl=${returnUrl}`;
  return (
    <Box verticalAlign='middle' gap={2}>
      <LanguageMenu />
      <Box gap={3} verticalAlign='middle'>
        <Box width={1} height={20} backgroundColor='D55' />
        <a
          href={loginUrl}
          rel='noreferrer'
          data-hook={DATA_HOOKS.HEADER_LOGIN}
          onClick={() => onLinkClick(t('header.log-in'), loginUrl)}
        >
          <TextButton
            className={css.textButton}
            skin='dark'
            weight='normal'
            underline='always'
          >
            {t('header.log-in')}
          </TextButton>
        </a>
      </Box>
      <a
        href={signupUrl}
        target='_blank'
        rel='noreferrer'
        data-hook={DATA_HOOKS.HEADER_GET_STARTED}
        onClick={() => onLinkClick(t('header.get-started'), signupUrl)}
      >
        <Button size='medium' skin='dark'>
          {t('header.get-started')}
        </Button>
      </a>
    </Box>
  );
};
