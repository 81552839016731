import { pageClick, wasItHelpfulView } from '@wix/bi-logger-customer-care-data/v2';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { BI } from '../../constants';
import { Context } from '../../context';
import { useBI } from '../../hooks/useBI';
import { mapPageTypeToSourceName } from '../../utils/bi';

export const useHeaderBI = () => {
  const { sendBIEvent } = useBI();
  const { locale } = useRouter();
  const { pageTemplateData: { itemId }, pageType, sessionBI: { selfServiceSessionId } } = useContext(Context);

  const onLinkClick = async (clickedText: string, clickedUrl: string, clickedItemId?: string) => {
    await sendBIEvent(
      pageClick({
        self_service_session_id: selfServiceSessionId ?? undefined,
        source_name: mapPageTypeToSourceName(pageType),
        kb_lang: locale,
        item_id: itemId,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.HEADER_CLICK,
        clicked_text: clickedText,
        clicked_url: clickedUrl,
        clicked_item_id: clickedItemId,
      })
    );
  };

  const onItemView = async (itemName: string) => {
    await sendBIEvent(
      wasItHelpfulView({
        self_service_session_id: selfServiceSessionId ?? undefined,
        source_name: mapPageTypeToSourceName(pageType),
        kb_lang: locale,
        item_name: itemName,
      }));
  };

  return { onLinkClick, onItemView };
};
